import React, { useState } from "react";
import "./App.css"
import Card from "./components/UI/Card";
import ExpenseForm from "./components/ExpenseForm/ExpenseForm";
import Expense from "./components/ExpenseItem/Expense";

const Dummy = [
  { id: 'e1', title: 'Something Something', amount: 94.12, date: new Date(2020, 0, 14) },
  { id: 'e4', title: 'New Desk (Wooden)', amount: 450, date: new Date(2021, 5, 12) },
  { id: 'e3', title: 'Car Insurance', amount: 294.67, date: new Date(2021, 2, 28) },
  { id: 'e2', title: 'New TV', amount: 799.49, date: new Date(2021, 2, 12) }
];

const App = () => {
  const [expenses, setExpenses] = useState(Dummy);

  const formAddNew = (expense) => {
    setExpenses((prevState) => {
      return ([expense, ...prevState]);
    });
  }

  return (
    <Card className="Main-Card">
      <ExpenseForm onFormSubmit={formAddNew}></ExpenseForm>\
      <Expense expenseList={expenses} />
    </Card>
  );
}
export default App;
